<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('users.change-password') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" md="3" sm="5">
          <ProfileMenu :userEmail="user.email" />
        </b-col>
        <b-col cols="12" md="9" sm="7" class="pl-4">
          <b-overlay :show="overlayPasswordForm">
          <b-form @submit="onSubmit" autocomplete="off">
            <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('users.current-password')" label-for="currentPassword">
              <b-form-input @blur="curentPasswordBlur" :state="verifiedPassword" id="currentPassword" v-model="curentPassword" type="password" :placeholder="$t('users.enter-current-password')" autocomplete="new-password"></b-form-input>
              <b-form-invalid-feedback id="currentPasswordFeedBack">
                {{ $t('users.password-incorect') }}
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('users.new-password')" label-for="newPassword">
              <b-form-input id="newPassword" v-model="newPassword" type="password" :placeholder="$t('users.enter-new-password')" autocomplete="new-password"></b-form-input>
            </b-form-group>
            <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('users.repeat-new-password')" label-for="repeatedNewPassword">
              <b-form-input id="repeatedNewPassword" v-model="repeatedNewPassword" type="password" :placeholder="$t('users.enter-repeated-new-password')" autocomplete="new-password"></b-form-input>
            </b-form-group>
            <b-form-group label-cols-sm="12" label-cols-md="4">
              <b-overlay :show="saveSpinner" spinner-small spinner-variant="secondary" opacity="0.5" class="d-inline-block">
                <b-button :disable="saveSpinner" type="submit" variant="primary">{{ $t('users.change-password')}}</b-button>
              </b-overlay>
            </b-form-group>
          </b-form>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"

export default {
  name : "ChangePassword",
  components: { NavBar},
  data: function() {
    return {
      user: {},
      overlayPasswordForm: true,
      curentPassword: "",
      newPassword: "",
      repeatedNewPassword: "",
      verifiedPassword: null,
      saveSpinner: false
    }
  },

  computed: {
    myUser: function() {
      return this.user;
    }
  },

  created: function() {
    this.$http.get('/cms/api/users/get/email/' +  this.$route.params.username + "/" + this.$root.lang.IANA, { headers: {Authorization: this.getAuthData().accessToken }})
      .then(response => {
        this.user = response.data;
        this.overlayPasswordForm = false;
      })
      .catch(error => { this.handleError(error);  })
  },

  methods: {
    onSubmit: function() {
      event.preventDefault();
      this.saveSpinner = true;
      this.user.password = this.newPassword;
      this.user.repeatedPassword = this.repeatedNewPassword;
      const data = new FormData();
      // Tekstualne podatke šaljemo kao binarni objekat
      data.append("userForm", new Blob([JSON.stringify(this.user)], {type: "application/json"}));
      data.append("image", null);
      const config = {
        headers: {"Content-Type": undefined, Authorization: this.getAuthData().accessToken}
      };
      this.$http.post("/cms/api/users/sign-up", data, config)
        .then(r => {
          if (r.data === "OK") {
            this.infoMessages = {type:"success", text: this.$t('users.change-password-success')};
          } else {
            this.infoMessages = {type:"danger", text: this.$t('users.change-password-error')};
          }
      }).catch(e => {
        this.infoMessages = {type:"danger", text: this.handleError(e)};
      })
      this.saveSpinner = false;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },

    curentPasswordBlur: function() {
      const data = {
        password : this.curentPassword,
        email    : this.user.email
      }; 
      this.$http.post("/cms/api/users/check-password-integrity/", data, {headers: {Authorization: this.getAuthData().accessToken}}).then(r => {
        if (r.data === "NOTOK") {
          this.verifiedPassword = false;
        } else {
          this.verifiedPassword = null;
        }
      }).catch(e => { this.handleError(e); })
    }
  }
}
</script>
<style></style>
